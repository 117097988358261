.button{
    border: 1px solid var(--borderColor);
    width: 150px;
    text-align: center;
    transition: ease .5s;
}
.button a, .button input{
    padding: 10px;
    width: 100%;
    display: inline-block;
    text-decoration: none !important;
    transition: .5s;

    background-color: var(--backgroundColor);
    box-shadow: none !important;
    border: none;
    color: var(--textColor) !important;
}
.button:hover{
    border: 1px solid var(--baseColor) !important;
}
.button:hover a, .button:hover input{
    color: var(--baseColor) !important;
}