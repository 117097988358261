@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");
@import url("fontawesome/css//all.min.css");

*{
    box-sizing: border-box;
}
:root{
    --textColor: #9da1a5;
    --borderColor: #313131;
    --backgroundColor: #191a1c;
    --baseColor: #f473df;
    --baseColor: #5fd38d;
}
p, h2, h3, h4, i, input, textarea, tr, ul{
    color: var(--textColor);
}
ul {
    list-style: none; /* Remove default bullets */
}
li::before {
    content: "⧫"; 
    color: var(--baseColor);
    width: 1em;
    display: inline-block;
}
strong{
    color: var(--baseColor);
    font-weight: normal;
}
body{
    background-image: url("../images/bg.svg");
    background-position: center;
    background-repeat: repeat-y;
    background-size: 100%;
    background-color: #191a1c;
    overflow-x: hidden;
}