.projectTwo, .projectThree{
    display: flex;
    justify-content: space-between;
}
.projectTwo .projectElement,
.projectThree .projectElement{
    height: 350px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.30);
}
.projectTwo .projectElement{
    width: 49%;
    transition: .5s;
    position: relative;
}
.hoverDetails{
    width: 100% !important;
    height: 0%;
    overflow: hidden;
    position: absolute;
    background-color: rgba(25, 26, 28, .90);
    transition: .5s;
}
.hoverContent{
    padding: 20px;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.projectThree .projectElement{
    width: 32%;
    transition: .5s;
    position: relative;
}
.projectThree .hoverDetails {
    width: 100% !important;
    height: 0% !important;
    overflow: hidden;
    position: absolute;
    background-color: rgba(25, 26, 28, .90);
    transition: .5s;
}
.projectTwo .projectElement:hover .hoverDetails,
.projectThree .projectElement:hover .hoverDetails{
    height: 100% !important;
}

@media screen and (max-width: 1000px) {
    .projectTwo .projectElement,
    .projectThree .projectElement{
        height: 300px;
        width: 100%;
        margin-top: 20px;
    }

    .toLeft{
        left: 0;
    }
    .projectThree .hoverDetails {
        height: 100% !important;
        width: 0% !important;
    }

    .projectThree .projectElement:hover .hoverDetails{
        width: 100% !important;
    }

    .projectTwo, .projectThree{
        flex-direction: column;
        margin: 0 !important;
    }
}