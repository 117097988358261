header{
    width: 100%;
    height: 100vh;
    position: relative;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #191a1c !important;
    background-image: url("../../images/33253.png") !important;
    background-attachment: fixed;
    
    text-align: center;

    display: flex;
    align-items: center;
}
h1{
    font-size: 80px !important;
    color: var(--baseColor);
}
header h2{
    font-size: 30px;
    color: white;
    margin-bottom: -15px !important;
}