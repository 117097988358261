nav{
    padding: 10px;
    width: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
}
nav .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#logo{
    width: 40px
}
.menuScreen{
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, .8);

    z-index: 98;
    transition: .4s;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}
.menuScreen a{
    font-size: 30px;
    color: white;
    text-decoration: none;
    transition: .3s;
}
.menuScreen a:hover{
    color: var(--baseColor);
}
.menuScreen div{
    display: flex;
    flex-direction: column;
}