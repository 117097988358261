section{
    padding-bottom: 30px;
}
section .container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
section h2{
    margin-top: 40px;
}
.sideToSide{
    display: flex;
    justify-content: center;
}
.sideToSide img{
    width: 100%;
    margin-left: -00px;
    margin-right: 0 !important;
}
.sideToSide .img{
    width: 50%;
}

.sideToSide .text{
    padding: 20px;
    display: flex;
    width: 50%;
    justify-content: center;
    flex-direction: column;
}
.sideToSide .text h3{
    margin-left: -100px
}
.sideToSide .text p{
    margin-left: -50px
}

@media screen and (max-width: 1000px){
    .sideToSide .img, 
    .sideToSide .text{
        width: 100%;
    }
    .sideToSide .text p,
    .sideToSide .text h3{
        margin-left: 0px
    }

    .sideToSide{
        flex-direction: column;
    }
}